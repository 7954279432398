/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM049P
 * 화면설명 : 세대원 입력 (지류 고객등록)
 * 작 성 일 : 2023.04.03
 * 작 성 자 : sh.park2022

 * 기존 세대원: 태아가 비활성화/나머지 활성화 (일련번호 동일)
 */
<template>
  <ur-page-container title="세대추가" class="msp" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area mb30">

        <!-- Contents ::START -->
        <ur-box-container alignV="start" componentid="" direction="column">
          <mo-list :list-data="lv_CmtdHoshList">
            <template #list-item="{item, index}">
              <!-- 세대원 입력 영역 :expanded="index === lv_CmtdHoshTot-1"-->
              <mo-list-item class="msp_list--full">
                <msp-expand :ref="'refHosh'+index" btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 full "> 
                  <!-- 타이틀 -->
                  <template #title>
                    <div class="fexTy3 relative">              
                      <ur-box-container alignV="start" componentid="" direction="column" class="">
                        <mo-text-field v-model="item.custNm" class="form-input-name input-pr80" underline placeholder="세대원 이름을 입력하세요"
                                       maxlength="30" :disabled="item.isBaby === 'Y'" @focus="$refs['refHosh'+index].isExpand = true" @blur="fn_CheckCustNm(item)" @input="fn_InputCustNm(item)" />
                        <mo-button v-if="item.isExistCustYN !== 'Y'" size="small" class="blue bd-radius-30 absolute wauto right bottom5" @click="fn_DeletCnfmAlert(item)">동의제외</mo-button>
                      </ur-box-container>
                    </div>
                  </template>
                  <!-- 아코디언 버튼 -->
                  <template #btn>
                    <mo-button class="link-arrow down"></mo-button>
                  </template>
                  <!-- 세대원 입력 Content -->
                  <template #content>
                    <div class="list-item__contents pl4 pr4">

                      <!-- 주고객 관계 -->
                      <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                        <msp-bottom-select class="ns-dropdown-sheet" :items="lv_mnCustRlnt" v-model="item.mnCustRltnCd" itemText="label" itemValue="key"
                                        underline bottom-title="선택" closeBtn scrolling  placeholder="관계" :disabled="item.isBaby === 'Y'"/>
                      </ur-box-container>

                      <!-- 외국인 체크박스 -->
                      <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
                        <mo-checkbox v-model="item.isForig" class="ns-check mt15 pal0 flex" :disabled="item.isBaby === 'Y' || item.custType !== 'new'"
                                    @input="fn_InputForig(item, index)">외국인</mo-checkbox>
                      </ur-box-container>

                      <!-- 일련번호 조회 -->
                      <ur-box-container v-if="item.isExistCustYN === 'Y' || !item.isCustScCd" alignV="start" componentid="ur_box_container_004" direction="column" class="ns-round-btn bdnone pal0">
                        <mo-button class="ns-btn-round mt45" @click="fn_OpenMSPCM041P(item)" :disabled="item.isBaby === 'Y'">일련번호 조회</mo-button>
                      </ur-box-container>

                      <!-- 미확보 체크박스 -->
                      <ur-box-container v-if="!item.isForig && item.custType === 'new'" alignV="start" componentid="ur_box_container_004" direction="column">
                        <mo-checkbox v-model="item.isCustScCd" class="ns-check mt10 flex" @input="fn_CheckCustSc(item)">미확보</mo-checkbox>
                      </ur-box-container>

                      <!-- 생년월일 -->
                      <div class="verify-birth mt10">
                        <mo-text-field ref="knbFrno" v-model="item.knbFrno" :rules="targetRule" type="number" underline class="birth-input3 form-input-name"
                                       :placeholder="!item.isForig ? item.isCustScCd ? '생년월일 8자리' : '생년월일 6자리' : '생년월일'"
                                        :mask="item.isCustScCd ? '########' : '######'" :disabled="item.isBaby === 'Y' || !item.isCustScCd"/>
                        <span>-</span>
                        <mo-text-field v-if="!item.isCustScCd" underline class="birth-input3" placeholder="*******" disabled/>
                        <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="row" class=" w50">
                          <!-- 성별 라디오 -->
                          <div class="ns-radio-list flex here">
                            <mo-radio v-model="item.rdoSxdsCd" value="1">남</mo-radio>
                            <mo-radio v-model="item.rdoSxdsCd" value="2">여</mo-radio>
                          </div>
                        </ur-box-container>
                      </div>
                      
                      <!-- 외국인정보 영역 -->
                      <template v-if="item.isForig">
                        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="mt45 pb10">
                          <span class="ns-title must mb0">영문명</span>
                          <mo-text-field v-model="item.custEngNm" :ref="`custEngNm${index}`" class="form-input-name" underline :disabled="!!item.natyCd" placeholder="외국인등록증상의 성명을 입력바랍니다" :rules="CheckCustEngNmRule" @input="fn_InputCustEngNm(item)" />
                        </ur-box-container>
                        <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="pb10">
                          <span class="ns-title must mb0">국적정보</span>
                          <div class="w100">
                            <mo-text-field v-model="item.natyNm" class="form-input-name" :disabled="!!item.natyCd" readonly underline placeholder="국적조회 클릭시 국적 자동반영"/>
                            <mo-button class="ns-btn-round white sm" style="position: absolute; right: 25px;" :disabled="!!item.natyCd" @click="fn_SelNatyInfo(item, index)">국적조회</mo-button>
                          </div>
                        </ur-box-container>
                        <ur-box-container :key="compKey[index]" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-agreeArea2">
                          <div class="agree-box2" style="padding: 0;">
                            <div class="ns-segment ns0000001">
                              <mo-segment-wrapper v-model="item.dmclScCd" solid primary style="margin: 0;" @input="fn_InputDmclScCd(item, index)">
                                <mo-segment-button value="01">국내거주</mo-segment-button>
                                <mo-segment-button value="02">국외거주</mo-segment-button>
                              </mo-segment-wrapper>
                            </div>
                          </div>
                        </ur-box-container>
                      </template>

                      <!-- 직업 -->
                      <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class=" pal0 mt45">
                        <span class="fs17rem fwb">직업</span>

                        <div class="flex fex-clumn mt15">
                          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="row" class=" w50">
                            <div class="ns-radio-list flex fex-clumn">
                              <mo-radio v-model="item.jobCd" value="116100" @input="fn_SetJobCdNm(item)" :disabled="item.isBaby === 'Y'">미취학아동</mo-radio>
                              <mo-radio v-model="item.jobCd" value="112200" class="mt18" @input="fn_SetJobCdNm(item)" :disabled="item.isBaby === 'Y'">초,중,고등학생</mo-radio>
                            </div>
                          </ur-box-container>
                        </div>
                      </ur-box-container>
                      <!-- 다른 직업 찾기 -->
                      <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class=" pal0 mt20">
                        <mo-text-field v-model="item.jobCdNm" searchable underline readonly placeholder="다른 직업 찾기" @click="fn_OpenMSPCM013P(item)" :disabled="item.isBaby === 'Y'"/>
                      </ur-box-container>

                    </div>
                  </template>
                </msp-expand>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-round-btn">
          <mo-button class="ns-btn-round" @click="fn_Vaidation('add')">세대원 추가</mo-button>
        </ur-box-container>
      </ur-box-container>
      <!-- Contents ::END -->

      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BtnCencel">취소</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Vaidation('confirm')">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPCM013P from '@/ui/cm/MSPCM013P' // 보험직업 조회
import MSPCM041P from '@/ui/cm/MSPCM041P' // 일련번호 조회 
import cmConstants from '~/src/config/constants/cmConstants';
  
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM049P',
  // 현재 화면 ID
  screenId: "MSPCM049P",
  components: {
    MSPBottomAlert
  },
  props: {
    pExistHoshList: {
      type: Array,
      default: []
    },
    pNewHoshList: {
      type: Array,
      default: []
    }
  },
  modalProps: {
    mode: 'right'
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM049P ■■■■■■■')

    this.fn_Init()
  },
  mounted() {
    // 화면접속로그 등록(필수)
    // this.$bizUtil.insSrnLog('MSPCM043M')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data () {
    return {
      lv_mnCustRlnt: [],

      targetRule: [
        v => !!v || '필수입력항목입니다',
        v => v.length > 7 || '생년월일 8자리를 입력하세요.'
      ],

      lv_HoshList: [],     // 세대원 리스트
      lv_ExitHoshList: [], // 기존 세대원 리스트
      lv_NewHoshList: [],  // 신규 세대원 리스트

      lv_SelctedHoshItem: {},

      // 추가 세대원 VO
      lv_AddHoshObj: {
        custNm: '',  // 고객명
        custType: 'new', // 신규 세대원
        knbFrno: '', // 생년월일
        rdoSxdsCd: '1', // default 남
        mnCustRltnCd: '03', // 주고객관계 코드
        jobCd: '116100',    // 직업 - default 미취학아동('116100')
        jobCdNm: '미취학아동',
        isForig: false, // 외국인여부
        isBaby: 'N',    // 태아여부 (추가 세대원은 default: 'Y')
        isCustScCd: false, // 미확보 여부
        knbScCd: 'ZPER01',  // 국적구분코드 (ZPER01: 내국인)
        unAprvCustYn: 'Y', // 미승인 고객 (default: 'Y')
        isExistCustYN: 'N', // 기존고객 여부
      },

      natyCdList: [], // 국적 공통코드 목록
      compKey: [], // 컴포넌트 키 (화면 업데이트용)

      /* Validation */
      isNotEmptyCustEngNm: true,
      isValidCustEngNm: true,
      CheckCustEngNmRule: [
        v => (this.isNotEmptyCustEngNm = !!v, !!v) || '영문명은 필수값 입니다.',
        v => (this.isValidCustEngNm = this.$bizUtil.isCustNameValidation(v, 'ZPER02')) || this.$t('cm')['ECMC055'] // '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)',
      ],
    };
  },
  watch: {
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    lv_CmtdHoshList() {
      let exitHoshList = this.lv_ExitHoshList
      let newHoshList = this.lv_NewHoshList

      let hoshList = [...exitHoshList, ...newHoshList]

      hoshList.forEach(item => {
        this.fn_SetJobCdNm(item)
      });

      // 화면 새로고침용 컴포넌트 키 초기세팅
      this.compKey = Array(hoshList.length).fill(0)

      return hoshList
    },
    lv_CmtdHoshTot() {
      return _.isEmpty(this.lv_CmtdHoshList) ? 0 : this.lv_CmtdHoshList.length
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      this.lv_mnCustRlnt = cmConstants.HOSH_CUST_RLTN_CD // 세대원-주고객관계코드

      // 국적조회 공통코드 세팅
      this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD')
        .then(() => {
          this.natyCdList = this.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')
        })

      this.fn_SetHoshList()
    },
    /*********************************************************
     * @function fn_SetHoshList
     * @notice   세대원 목록 정보 셋팅
    *********************************************************/
    fn_SetHoshList() {
      this.fn_SetExistHosh()

      let exitHoshList = this.$bizUtil.cloneDeep(this.pExistHoshList)
      let newHoshList = this.pNewHoshList
      let hoshList = [...exitHoshList, ...newHoshList]

      this.lv_ExitHoshList = !_.isEmpty(exitHoshList) ? exitHoshList : []
      this.lv_NewHoshList = !_.isEmpty(newHoshList) ? newHoshList : []
      
      if (_.isEmpty(hoshList)) {
        this.fn_AddHoshItem()
        hoshList = this.lv_NewHoshList
      }

      this.lv_HoshList = hoshList
    },
    /*********************************************************
     * @function fn_SetExistHosh
     * @notice   기존 세대원 목록 정보 셋팅
    *********************************************************/
    fn_SetExistHosh () {
      this.pExistHoshList.forEach(item => {
        item.rdoSxdsCd = '1'
        item.isForig = item.knbScCd === 'ZPER02' // 외국인 여부 (ZPER02: 외국인)
        item.isCustScCd = false
        item.jobCd = item.custJobCd // 직업코드
        item.jobCdNm = item.custJobCdNm // 직업코드명
        item.isBaby = 'N'
        
        // 세대원 태아인경우 수정 불가
        if (item.custNm === '태아') {
          item.isBaby = 'Y'
        }
      });

    },
    /******************************************************************************
    * Function명 : fn_InputCustNm
    * 설명       : 고객명 Input 이벤트
    ******************************************************************************/
    fn_InputCustNm(item) {
      item.custNm = item.custNm?.toUpperCase();
    },
    /******************************************************************************
    * Function명 : fn_InputForig
    * 설명       : 외국인 체크박스 Input 이벤트
    ******************************************************************************/
    fn_InputForig(item, index) {
      item.isCustScCd = false

      item.dmclScCd = item.isForig ? '01' : '' // 거주구분 초기화
      this.fn_InputDmclScCd(item, index)
    },
    /******************************************************************************
    * Function명 : fn_InputDmclScCd
    * 설명       : 거주구분 Input 이벤트
    ******************************************************************************/
    fn_InputDmclScCd(item, index) {
      item.bpDmclScCd = item.dmclScCd
    
      if (item.dmclScCd) {
        item.dmclScCdNm = item.dmclScCd === '01' ? '국내' : '해외'
        item.bpDmclScCdNm = item.dmclScCd === '01' ? '국내' : '해외'
      } else {
        item.dmclScCdNm = ''
        item.bpDmclScCdNm = ''
      }
      this.$set(this.compKey, index, this.compKey[index] + 1) // 거주구분 화면 새로고침
    },
    /******************************************************************************
    * Function명 : fn_InputCustEngNm
    * 설명       : 영문명 Input 이벤트
    ******************************************************************************/
    fn_InputCustEngNm(item) {
      item.custEngNm = item.custEngNm?.toUpperCase();
    },
    /******************************************************************************
    * Function명 : fn_SelNatyInfo
    * 설명       : 국적조회
    ******************************************************************************/
    fn_SelNatyInfo (item, index) {
      if (!item.knbFrno || !item.knbBknoCipher) return this.getStore('confirm').dispatch('ADD', '일련번호 조회 후 가능합니다.')

      this.$refs[`custEngNm${index}`].validate()
      if (!this.isNotEmptyCustEngNm || !this.isValidCustEngNm) return;

      const pParams = {
        nameLast: item.custEngNm, // 영문명
        knbFrno: item.knbFrno, // 외국인등록번호 앞번호 
        knbBknoCipher: item.knbBknoCipher, // 외국인등록번호 뒷번호(암호화)
        trtrEno: this.getStore('userInfo').getters.getUserInfo.userId, // 처리자사번
        trtSystmPathDtlVal: 'MSPCM049P', // 처리시스템경로상세값 (EP에서 화면ID로 요청)
      }

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.trtrEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      // 매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      this.isLoading = true
      this.post(this, pParams, 'txTSSCM10I6', 'S')
        .then(response => {
          const data = response.body
          if (data) { 
            if (data.trtSc === 'G' && data.respCd === '7001') { // G: 정상, 7001: 등록된 외국인
              const natyObj = this.natyCdList.filter(item => item.key === response.body.natio)
              if (!_.isEmpty(natyObj)) {
                // 외국인고객 스크래핑 여부
                item.scrapYn = 'Y'

                item.bpCustEngNm = item.custEngNm

                item.natyNm = natyObj[0].label
                item.bpNatyNm = natyObj[0].label

                item.natyCd = natyObj[0].key
                item.bpNatyCd = natyObj[0].key
                
                this.$forceUpdate()
              } else {
                this.getStore("confirm").dispatch("ADD", '해당 국가 정보가 없습니다.')
              }
            } else {
              this.getStore("confirm").dispatch("ADD", `${item.custEngNm} 고객에 대한 ${data.mesg.trim() ? `${data.mesg}(으)로 인해` : ''} 재확인 후 다시 조회 바랍니다.`)
            }
          } else {
            this.getStore("confirm").dispatch("ADD", response.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
          this.getStore("confirm").dispatch("ADD", error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /****************************************************************************
     * 팝업 호출 Function 영역 ::START
     * @param ('popupID', CallbackFunction)
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_OpenMSPCM013P (hoshItem) {
      if (hoshItem.isBaby !== 'N') return

      this.lv_SelctedHoshItem = hoshItem
      this.fn_SetPopup('MSPCM013P', this.fn_ConfirmMSPCM013P)
    },
    /******************************************
     * @name 일련번호_검색
    ******************************************/
    fn_OpenMSPCM041P (hoshItem) {
      this.lv_SelctedHoshItem = hoshItem
      this.fn_SetPopup('MSPCM041P', this.fn_ConfirmMSPCM041P)
    },
    /****************************************************************************
     * 팝업 호출 Function 영역 ::END                                            *
    ****************************************************************************/

    /****************************************************************************
     * 팝업 CallBack Function 영역 ::START                                      *
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_ConfirmMSPCM013P (param) {
      const lv_Vm = this
      if (_.isEmpty(param)) return

      this.lv_SelctedHoshItem.jobCd = param.jobCd
      this.lv_SelctedHoshItem.jobCdNm = param.jobCdNm
      this.lv_SelctedHoshItem.isCustJobCdChanged = true // 세대원 직업 수정 여부

      // 선택한 세대원 Object값 초기화
      this.lv_SelctedHoshItem = {}
    },
    /******************************************
     * @name 일련번호_검색
    ******************************************/
    fn_ConfirmMSPCM041P (param) {
      if (_.isEmpty(param)) return

      console.log('CM041P param', param)

      if (!this.$bizUtil.isEmpty(param.rrn)) {
        this.lv_SelctedHoshItem.sno = param.sno
        this.lv_SelctedHoshItem.knbFrno = param.rrn.substr(0,6)
        this.lv_SelctedHoshItem.sxdsCd = param.rrn.substr(7,1)
        this.lv_SelctedHoshItem.knbBknoCipher = param.knbBknoCipher // 주민등록번호 뒷자리(암호화)
        this.lv_SelctedHoshItem.cnsltWcnstPblNo = param.cnsltWcnstPblNo // 동의서발행ID
        this.lv_SelctedHoshItem.isKnbChanged = true // 세대원 직업 수정 여부
      }

      // 선택한 세대원 Object값 초기화
      this.lv_SelctedHoshItem = {}
    },
    /***************************************************************
     * @function fn_SetPopup
     * @name     팝업_Props_셋팅
     * @notice   파라미터, Callback 함수 셋팅
     * @param    sheetId  :바텀시트ID
     * @param    fn_Callback :CallbackFunction
    ***************************************************************/
    fn_SetPopup (popId, fn_Callback) {
      const lv_Vm = this

      let popupObj = {}

      switch (popId) {
        case 'MSPCM013P': // 다른직업 조회
          popupObj.comp = MSPCM013P
          popupObj.props = { pJobCdNm : lv_Vm.lv_JobText } // 직업찾기 입력값
          break;
        case 'MSPCM041P': // 일련번호 조회
          popupObj.comp = MSPCM041P
          popupObj.props = {
            pCustInfo: this.lv_SelctedHoshItem.custType !== 'new' ? this.lv_SelctedHoshItem : {}
          }
          break;
      }

      this.fn_OpenPopup(popupObj, fn_Callback)
    },
    /****************************************************************************
     * 팝업 CallBack Function 영역 ::END                                        *
    ****************************************************************************/
   
    /***************************************************************
     * @function fn_OpenPopup
     * @name     팝업_호출
     * @notice   Util처럼 선언한 함수 (해당 화면 팝업사용이 많아 모두 등록)
     * @param    fn_Callback :CallbackFunction
    ***************************************************************/
    fn_OpenPopup (popupObj, fn_Callback) {
      let lv_PopupObj = this.$bottomModal.open(popupObj.comp,{
          properties: popupObj.props,
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(lv_PopupObj)

              if (typeof(fn_Callback) === 'function') {
                fn_Callback(pData)
              }
            },
            cancelPopup: (pData) => {
              this.$bottomModal.close(lv_PopupObj)

              if (typeof(fn_Callback) === 'function') {
                fn_Callback(pData)
              }
            },
            onPopupConfirm: (pData) => {
              this.$bottomModal.close(lv_PopupObj)

              if (typeof(fn_Callback) === 'function') {
                fn_Callback(pData)
              }
            }
          }
        });

    },
    /***************************************************************
     * @function fn_AddHoshItem
     * @name     세대원_입력폼_추가
     * @notice   채널고객ID 임시로 타임스템프로 지정(유일성)
    ***************************************************************/
    fn_AddHoshItem () {
      this.fn_ExpandClose()

      let nowDate = new Date()
      let strDate = this.$bizUtil.getDateToString(nowDate, '')
      let strTime = this.$bizUtil.getTimeToString(nowDate, '')
      let timeTmp = strDate + strTime

      let addHoshObj = this.$bizUtil.cloneDeep(this.lv_AddHoshObj)
      addHoshObj.chnlCustId = timeTmp // 임시 채널고객ID

      this.lv_NewHoshList.push(addHoshObj)
    },
    /***************************************************************
     * @function fn_RemoveHosh
     * @name     세대원_입력폼_삭제
     * @notice   채널고객ID 임시로 타임스템프로 지정(유일성)
    ***************************************************************/
    fn_RemoveHosh (hoshItem) {
      this.fn_ExpandClose()

      this.lv_ExitHoshList = this.lv_ExitHoshList.filter(item => {
          return item.chnlCustId !== hoshItem.chnlCustId
        });

      this.lv_NewHoshList = this.lv_NewHoshList.filter(item => {
          return item.chnlCustId !== hoshItem.chnlCustId
        });
    },
    /***************************************************************
     * @function fn_CheckCustSc
     * @name     세대원_미확보_체크
     * @notice   미확보 체크박스 이벤트
    ***************************************************************/
    fn_CheckCustSc (hoshItem) {
      this.$refs.knbFrno.clearValidation()
      
      this.lv_HoshList.forEach(item => {
        if (item.chnlCustId === hoshItem.chnlCustId) {
          item.knbFrno = ''
        }
      });

    },
    /***************************************************************
     * @function fn_SetJobCdNm
     * @name     세대원_직업_라디오_클릭
     * @notice   라디오 클릭시, 직업코드명 셋팅
    ***************************************************************/
    fn_SetJobCdNm (hoshItem) {
      if (hoshItem.jobCd === '116100') {
        hoshItem.jobCdNm = '미취학아동'
      } else if (hoshItem.jobCd === '112200') {
        hoshItem.jobCdNm = '초,중,고등학생'
      }
    },
    /***************************************************************
     * @function fn_ExpandClose
     * @name     세대원_목록_아코디언_닫기
     * @notice   모든 세대원 입력폼 아코디언 Close
    ***************************************************************/
    fn_ExpandClose () {
      const lv_Vm = this

      this.lv_CmtdHoshList.forEach((item, index) => {
        if (!_.isEmpty(lv_Vm.$refs['refHosh'+index])) {
          lv_Vm.$refs['refHosh'+index].isExpand = false
        }
      });
      
    },
    /***************************************************************
     * @function fn_BtnCencel
     * @name     취소버튼
     * @notice   해당 팝업 닫기
    ***************************************************************/
    fn_BtnCencel () {
      this.$emit('onPopupCancel')
    },
    /***************************************************************
     * @function fn_Confirm
     * @name     확인버튼
     * @notice   신규세대원 셋팅후, 세대원 목록 전달
    ***************************************************************/
    fn_Confirm () {
      this.lv_CmtdHoshList.forEach(item => {
        if (item.custType === 'new') {
          if (!item.isCustScCd) { // 미확보 여부
              item.knbBkno = item.sxdsCd + '******'
  
            if (item.sxdsCd === '1' || item.sxdsCd === '2' || item.sxdsCd === '5' || item.sxdsCd === '6') {
              item.dob = '19' + item.knbFrno
              item.ralBrthd = '19' + item.knbFrno
            } else {
              item.dob = '20' + item.knbFrno
              item.ralBrthd = '20' + item.knbFrno
            }
          } else { // 미확보일 경우
            let knbBkFstNo = ''

            if (item.knbFrno.substr(0,2) === '19') { // 외국인-미확보 케이스는 없음
              knbBkFstNo = item.rdoSxdsCd % 2 === '1' ? '1' : '2'
              item.knbBkno = knbBkFstNo + '******'
            } else {
              knbBkFstNo = item.rdoSxdsCd % 2 === '1' ? '3' : '4'
            }
            item.knbBkno = knbBkFstNo + '******'
            item.dob = item.knbFrno
            item.ralBrthd = item.knbFrno
            item.knbFrno = item.knbFrno.substring(2)
          }
          
          item.custJobCd = item.jobCd
          item.custJobCdNm = item.jobCdNm

          item.knb = item.knbFrno + item.knbBkno
          item.knbScCd = !item.isForig ? 'ZPER01' : 'ZPER02'
        }
      });

      // 기존 세대원
      let existHosh = this.lv_CmtdHoshList.filter(item => {
          return item.custType !== 'new'
        });
      // 신규 세대원
      let newHosh = this.lv_CmtdHoshList.filter(item => {
        return item.custType === 'new'
          
        });
      
      let pParam = {
        existHoshInfoListInqrVO: existHosh,
        newHoshInfoListInqrVO: newHosh
      }

      this.$emit('onPopupConfirm', pParam)
    },
    /***************************************************************
     * @function fn_DeletCnfmAlert
     * @name     세대원_삭제_컨펌
     * @notice   세대원 삭제 확인 알럿
    ***************************************************************/
    fn_DeletCnfmAlert(hoshItem) {
      const lv_Vm = this

      let alertMsg = this.$t('cm')['ECMC014'] // '삭제하시겠습니까?'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title_pos_btn: '삭제',
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            lv_Vm.fn_RemoveHosh(hoshItem)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
    /***************************************************************
     * @function fn_OpenBottomAlert
     * @name     바텀시트_알럿
     * @notice   바텀시트 알럿 호출
    ***************************************************************/
    fn_OpenBottomAlert (alertMsg) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
    // -------------------------------------------------------------------------------------------------------------------------- //
    // ------------------------------------------------------- VALIDATION ------------------------------------------------------- //
    // -------------------------------------------------------------------------------------------------------------------------- //
    /***************************************************************
     * @function fn_Vaidation
     * @name     유효성체크
     * @notice   세대원 추가/확인 클릭시 호출 함수
     *           고객명과 주민번호 앞자리 체크
    ***************************************************************/
    fn_Vaidation (flag) {
      let custItem = {}
      let posFlag = false

      this.fn_ExpandClose() // 아코디언 Close

      for (let i=0; i<this.lv_CmtdHoshList.length; i++) {
        custItem = this.lv_CmtdHoshList[i]

        if (this.fn_CheckCustNm(custItem) && this.fn_CheckKnbFrno(custItem) && this.fn_CheckNatyInfo(custItem)) {
          posFlag = true
        } else {
          this.$refs['refHosh'+i].isExpand = true
          posFlag = false
          break;
        }
      } // for i

      if (posFlag) {
        if (flag === 'add') {
          this.fn_AddHoshItem()
        } else if (flag === 'confirm') {
          this.fn_Confirm()
        }
      }

    },
    /***************************************************************
     * @function fn_CheckCustNm
     * @name     고객명체크
     * @notice   세대원 추가/확인 클릭시 호출
    ***************************************************************/
    fn_CheckCustNm (custItem) { // 세대원 명 유효성 체크
      let alertMsg = ''
      let rtnValue = true

      if (_.isEmpty(custItem)) return rtnValue

      let lv_CustNmResult = custItem.custNm.trim().toUpperCase()
      let regexp_eng = '[a-zA-Z]' // 영문(대소)

      custItem.custNm = lv_CustNmResult
      
      // 한글 10글자, 영문은 30글자 제한
      if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
        if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
          lv_CustNmResult = lv_CustNmResult.substr(0, 30)
          custItem.custNm = lv_CustNmResult
        } else {
          lv_CustNmResult = lv_CustNmResult.substr(0, 10)
          custItem.custNm = lv_CustNmResult
        }
      }
      
      if (this.$bizUtil.isEmpty(custItem.custNm)) {
        rtnValue = false
        alertMsg = '이름을 입력해주세요'
        this.fn_OpenBottomAlert(alertMsg)
      }

      let nameVali = this.$bizUtil.isCustNameValidation(lv_CustNmResult, !custItem.isForig ? 'ZPER01' : 'ZPER02')
      if (!nameVali) { // 이름 적합성 체크
        rtnValue = false

        if (lv_CustNmResult.search(regexp_eng) > -1 && !custItem.isForig) { // 영문이 있으면
          alertMsg = '외국인 체크를 해주세요'
          this.fn_OpenBottomAlert(alertMsg)
        } else {
          alertMsg = this.$t('cm')['ECMC055'] // '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
          this.fn_OpenBottomAlert(alertMsg)
        }
      }

      return rtnValue
    },
    /***************************************************************
     * @function fn_CheckKnbFrno
     * @name     생년월일체크
     * @notice   세대원 추가/확인 클릭시 호출
    ***************************************************************/
    fn_CheckKnbFrno (custItem) {
      let alertMsg = ''
      let rtnValue = true
      
      if (_.isEmpty(custItem)) return rtnValue

      if (custItem.custNm !== '태아') {
        if (this.$bizUtil.isEmpty(custItem.knbFrno) || custItem.knbFrno.length < 6) {
          rtnValue = false
          alertMsg = '주민등록번호 항목은 필수 정보입니다.'
          this.fn_OpenBottomAlert(alertMsg)
        } else {
          if (custItem.isCustScCd) { // 미확보일 경우
            if (this.$bizUtil.isEmpty(custItem.knbFrno) || custItem.knbFrno.length < 8) {
              rtnValue = false
              this.$refs.knbFrno.validate()
            }
          } else {
            // 날짜 적합성 체크
            if (!this.$cmUtil.isSuitaDate(custItem.knbFrno)) {
              rtnValue = false
              alertMsg = '유효하지 않은 생년월일 정보입니다. 정확한 정보를 확인하시기 바랍니다.'
              this.fn_OpenBottomAlert(alertMsg)
            }
          }
        }
      }

      return rtnValue
    },
    /***************************************************************
     * @function fn_CheckNatyInfo
     * @name     생년월일체크
     * @notice   세대원 추가/확인 클릭시 호출
    ***************************************************************/
    fn_CheckNatyInfo (custItem) {
      let alertMsg = ''
      let rtnValue = true
      
      if (_.isEmpty(custItem)) return rtnValue

      if (custItem.isForig && (this.$bizUtil.isEmpty(custItem.custEngNm) || this.$bizUtil.isEmpty(custItem.natyCd))) {
        rtnValue = false
        alertMsg = '국적조회를 클릭하세요.'
        this.fn_OpenBottomAlert(alertMsg)
      }

      return rtnValue
    },
  } // ::Methods End
};
</script>
